<template>
  <div v-loading="loading" id="business_overview">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务数据总览</span
        ><span>
          <span class="statistics">新增商务数：{{ newBusinessCount }}</span>
          <span class="statistics">立项数：{{ projectCount }}</span>
          <span class="statistics"> 合同签约数：{{ contractSigningCount }}</span>
        </span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessOverview'"
            :introductionType="'staffId'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.overviewPrincipal"
            v-model="sectionInfo.overviewYear"
            @change="onOverviewChange"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'BusinessOverview'"
            :introductionType="'staffId'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.overviewYear"
            v-model="sectionInfo.overviewPrincipal"
            @change="onOverviewChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessOverviewImg"
          >数据来源说明</el-button
        >
      </div>
      <template v-if="isBoss">
        <el-table
          :summary-method="getSummaries"
          show-summary
          border
          :height="tableHeight"
          :data="overviewList"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            fixed="left"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="principalUserName"
            label="商务负责人"
            align="center"
            fixed="left"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="businessCount"
            min-width="90"
            label="商务数"
            sortable
            align="right"
          ></el-table-column>
          <el-table-column prop="projectCount" min-width="90" label="立项数" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>立项数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>已立项的商务数</div>
                </div>
              </el-tooltip></template
            ></el-table-column
          >
          <el-table-column
            prop="successProjectCount"
            min-width="90"
            label="成功实施项目数"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>成功实施项目数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>成功实施项目数 = 总项目数 - 未启动 - 终止（无代收款）</div>
                </div>
              </el-tooltip></template
            ></el-table-column
          >

          <el-table-column
            prop="businessEndCount"
            min-width="90"
            label="商务终结数"
            sortable
            align="right"
          >
          </el-table-column>
          <el-table-column
            prop="contractSignedCount"
            min-width="90"
            label="签约合同数"
            sortable
            align="right"
          >
          </el-table-column>
          <el-table-column
            prop="saleAmount"
            min-width="130"
            label="我方合同金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>我方合同金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务详情里的我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.saleAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceAmount"
            min-width="130"
            label="开票金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>开票金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所创建的项目，项目收入中的已开票金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.invoiceAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceRate"
            min-width="100"
            label="开票占合同比例"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>开票占合同比例</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>开票额 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.invoiceRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column
            prop="repaymentAmount"
            min-width="130"
            label="回款金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>回款金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所创建的项目，项目收入中的已收款金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.repaymentAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="repaymentInvoiceRate"
            min-width="100"
            label="回款占开票比例"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>回款占开票比例</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>回款金额 / 开票金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.repaymentInvoiceRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column
            prop="saleRepaymentRate"
            min-width="100"
            label="回款比例"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>回款比例</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>回款金额 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.saleRepaymentRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="businessExpenseTotal" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>商务费用总和</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所产生的报销费用、外协费用合计</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.businessExpenseTotal | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="successBusinessExpense" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>成功商务费用</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>指非商务终结的商务所产生的报销费用、外协费用合计</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.successBusinessExpense | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="failBusinessExpense" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>不成功商务费用</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>指商务终结的商务所产生的报销费用、外协费用合计</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.failBusinessExpense | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="expenseSaleRate"
            min-width="100"
            label="费销比"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>费销比</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>成功商务费用 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.expenseSaleRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column
            prop="businessConversionRate"
            min-width="100"
            label="商务转化率"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>商务转化率</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>立项数 / 商务数</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.businessConversionRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" min-width="70">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="onVivewUser(scope.row)"
                class="text_Details_Bgc"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <div class="business_individual" v-for="(item, index) in overviewList" :key="item.name">
          <div class="business_individual_title">
            {{ item.name }}
          </div>
          <template v-if="index <= 5">
            <div class="business_individual_center">{{ item.value | thousands }}</div>
          </template>
          <template v-else-if="index != 7 && index != 9 && index != 10 && index < 14">
            <div class="business_individual_center">{{ item.value | applyAmount }}</div>
          </template>
          <template v-else>
            <div class="business_individual_center">{{ Number(item.value * 100).toFixed(2) }}%</div>
          </template>
        </div>
      </template>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px" v-if="!isBoss">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务费用分析</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessResponse'"
            :introductionType="'staffId'"
            :twoIntroductionType="'businessId'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.responsePrincipal"
            :threeValue="sectionInfo.businessResponseId"
            v-model="sectionInfo.responseYear"
            @change="onBusinessResponseChange"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'BusinessResponse'"
            :introductionType="'staffId'"
            :twoIntroductionType="'businessId'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.responseYear"
            :twoValue="sectionInfo.responsePrincipal"
            :threeValue="sectionInfo.businessResponseId"
            v-model="sectionInfo.responsePrincipal"
            @change="onBusinessResponseChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'商务名称'"
            :width="'350'"
            :type="'BusinessResponse'"
            :introductionType="'staffId'"
            :twoIntroductionType="'businessId'"
            :threeIsShow="true"
            :clearable="true"
            :filterable="true"
            :list="businessNameList"
            :yearValue="sectionInfo.responseYear"
            :twoValue="sectionInfo.responsePrincipal"
            :threeValue="sectionInfo.businessResponseId"
            v-model="sectionInfo.businessResponseId"
            @change="onBusinessResponseChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessResponseImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        :summary-method="getIndividual"
        show-summary
        height="450px"
        border
        :data="responseList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="businessName"
          label="商务名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          v-if="isBoss && !sectionInfo.responsePrincipal"
          prop="principalUserName"
          label="商务负责人"
          align="center"
          fixed="left"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="isContractSigned"
          min-width="90"
          label="是否签署合同"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.isContractSigned > 1 ? '已签署' : '未签署' }}
          </template></el-table-column
        >
        <el-table-column
          prop="estimatedContractAmount"
          min-width="130"
          label="预计合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>预计合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情的预计合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedContractAmount | applyAmount }}
          </template></el-table-column
        >
        <el-table-column
          prop="actualContractAmount"
          min-width="130"
          label="我方合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualContractAmount | applyAmount }}
          </template></el-table-column
        >
        <el-table-column prop="amountReceived" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountReceived | applyAmount }}
          </template></el-table-column
        >

        <el-table-column prop="returnRatio" min-width="100" label="回款比例" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款比例</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>回款金额 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.returnRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column prop="estimatedCostUpperLimit" min-width="120" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>预计商务费用上限</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务负责人的商务记账上限</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedCostUpperLimit | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="businessCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>商务费用</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所产生的报销费用、外协费用合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.businessCost | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column
          prop="estimatedBusinessEffectiveness"
          min-width="130"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>预估商务绩效金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务结算中的实际绩效金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedBusinessEffectiveness | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column
          prop="salesExpenseRatio"
          min-width="110"
          label="费销比"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>费销比</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务费用 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.salesExpenseRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="80%" title="" :visible.sync="costIsShow">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务费用分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.responseYear"
              @change="onBusinessResponseChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.responseYear"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.responsePrincipal"
              @change="onBusinessResponseChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'商务名称'"
              :width="'350'"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :threeIsShow="true"
              :clearable="true"
              :filterable="true"
              :list="businessNameList"
              :yearValue="sectionInfo.responseYear"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.businessResponseId"
              @change="onBusinessResponseChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessResponseImg"
            >数据来源说明</el-button
          >
        </div>
        <el-table
          :summary-method="getIndividual"
          show-summary
          height="450px"
          border
          :key="Math.random()"
          :data="responseList"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            fixed="left"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="businessName"
            label="商务名称"
            align="center"
            fixed="left"
            min-width="240"
          ></el-table-column>
          <el-table-column
            v-if="isBoss && !sectionInfo.responsePrincipal"
            prop="principalUserName"
            label="商务负责人"
            align="center"
            fixed="left"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="isContractSigned"
            min-width="90"
            label="是否签署合同"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.isContractSigned > 1 ? '已签署' : '未签署' }}
            </template></el-table-column
          >
          <el-table-column
            prop="estimatedContractAmount"
            min-width="130"
            label="预计合同金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>预计合同金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务详情的预计合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedContractAmount | applyAmount }}
            </template></el-table-column
          >
          <el-table-column
            prop="actualContractAmount"
            min-width="130"
            label="我方合同金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>我方合同金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务详情的我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.actualContractAmount | applyAmount }}
            </template></el-table-column
          >
          <el-table-column prop="amountReceived" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>回款金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所创建的项目，项目收入中的已收款金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.amountReceived | applyAmount }}
            </template></el-table-column
          >

          <el-table-column
            prop="returnRatio"
            min-width="100"
            label="回款比例"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>回款比例</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>回款金额 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.returnRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="estimatedCostUpperLimit" min-width="120" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>预计商务费用上限</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务负责人的商务记账上限</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedCostUpperLimit | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="businessCost" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>商务费用</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所产生的报销费用、外协费用合计</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.businessCost | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="estimatedBusinessEffectiveness"
            min-width="130"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>预估商务绩效金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务结算中的实际绩效金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedBusinessEffectiveness | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="salesExpenseRatio"
            min-width="110"
            label="费销比"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>费销比</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务费用 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.salesExpenseRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '商务统计-数据总览'"
          src="@/assets/商务统计-数据总览.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '商务统计-商务费用分析'"
          src="@/assets/商务统计-商务费用分析.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 用户数组
      dictData: {
        yearList: [],
      },
      overviewList: [], // 数据总览
      responseList: [], // 商务费用分析
      sectionInfo: {
        // 条件筛选
        overviewYear: null, // 年份
        responseYear: null,
        // 负责人
        overviewPrincipal: '',
        responsePrincipal: '',
        businessResponseId: null, // 商务名称
      },
      isBoss: false, // 是否BOSS
      businessNameList: [], // 商务名称
      titleYear: '统计时间',
      titlePrincipal: '商务负责人',
      isDialog: false,
      imgUrl: '',
      costIsShow: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    newBusinessCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.businessCount
        } else if (v.name == '商务数') {
          num += v.value
        }
      })
      return num
    },
    projectCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.projectCount
        } else if (v.name == '立项数') {
          num += v.value
        }
      })
      return num
    },
    contractSigningCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.contractSignedCount
        } else if (v.name == '签约合同数') {
          num += v.value
        }
      })
      return num
    },
    tableHeight() {
      return window.innerHeight - 180 + 'px'
    },
  },
  created() {
    this.getType()
  },
  filters: {
    thousands(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {
    costIsShow: {
      handler: function (val) {
        if (!val) {
          this.sectionInfo.responsePrincipal = ''
          this.sectionInfo.businessResponseId = ''
        }
      },
    },
  },
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.businessDataOverview(`date=${val}`),
        this.$api.statistics.queryBusinessCostAnalysisSummary(`date=${val}`),
      ])
        .then(res => {
          // 数据总览
          this.overviewList = []
          this.isBoss = res[0].data.isBoss
          if (this.isBoss) {
            this.overviewList = res[0].data.businessDataOverviewResponseList || []
          } else {
            const { businessDataOverviewResponseList = [] } = res[0].data

            const keyMappings = {
              businessCount: '商务数',
              projectCount: '立项数',
              successProjectCount: '成功实施项目数',
              businessEndCount: '商务终结数',
              contractSignedCount: '签约合同数',
              saleAmount: '销售额',
              invoiceAmount: '开票额',
              invoiceRate: '开票占合同比例',
              repaymentAmount: '回款额',
              repaymentInvoiceRate: '回款占开票比例',
              saleRepaymentRate: '销售回款比例',
              businessExpenseTotal: '商务费用总和',
              successBusinessExpense: '成功商务费用',
              failBusinessExpense: '不成功商务费用总和',
              expenseSaleRate: '费销比',
              businessConversionRate: '商务转化率',
            }

            const newArr = []
            // 如果 businessDataOverviewResponseList 为空，则将所有值赋为 0
            if (businessDataOverviewResponseList.length === 0) {
              for (const key in keyMappings) {
                newArr.push({
                  name: keyMappings[key],
                  value: 0,
                })
              }
            } else {
              // 遍历 businessDataOverviewResponseList，收集数据
              businessDataOverviewResponseList.forEach(obj => {
                for (const key in obj) {
                  if (obj.hasOwnProperty(key) && keyMappings[key]) {
                    const userPrincipalInfo = {
                      name: keyMappings[key],
                      value: obj[key],
                    }
                    newArr.push(userPrincipalInfo)
                  }
                }
              })
            }
            // 根据 keyMappings 的顺序进行排序
            const sortedArr = Object.keys(keyMappings)
              .map(key => {
                return newArr.find(item => item.name === keyMappings[key])
              })
              .filter(item => item) // 过滤掉未找到的项

            // 将排序后的数组赋值回
            this.overviewList.push(...sortedArr) // 将排序后的元素推入原数组
          }
          this.responseList = res[1].data.businessCostAnalysisResponseList || []

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    async onVivewUser(row) {
      // // 去到页面底部
      // const demo = document.querySelector('#business_overview')
      // demo.scrollTop = demo.scrollHeight
      this.sectionInfo.responsePrincipal = row.principalUserId
      this.sectionInfo.responseYear = this.sectionInfo.overviewYear
      const res = await this.$api.statistics.queryBusinessCostAnalysisSummary(
        `date=${this.sectionInfo.responseYear}&staffId=${this.sectionInfo.responsePrincipal}`
      )
      this.responseList = res.data.businessCostAnalysisResponseList || []
      this.costIsShow = true
    },
    onBusinessResponseImg() {
      this.imgUrl = '商务统计-商务费用分析'
      this.isDialog = true
    },
    onBusinessOverviewImg() {
      this.imgUrl = '商务统计-数据总览'
      this.isDialog = true
    },
    onOverviewChange(arr) {
      this.overviewList = arr
    },
    onBusinessResponseChange(arr) {
      this.responseList = arr
    },
    // 表格合计
    getIndividual(param) {
      const { columns, data } = param
      const sums = []
      // 统一处理每一列的值
      const calculateSum = (columnIndex, columnProperty) => {
        const values = data.map(item => Number(item[columnProperty]))
        // 判断是否所有值都是有效的数字
        if (values.every(value => !isNaN(value))) {
          return values.reduce((prev, curr) => {
            const value = Number(curr)
            return isNaN(value) ? prev : prev + value
          }, 0)
        } else {
          return '' // 如果有无效数字，返回空字符串
        }
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 判断是否需要统计值
        const shouldCalculate =
          (this.isBoss && index > 3 && index !== 7 && index !== 11) ||
          (!this.isBoss && index > 2 && index !== 6 && index !== 10)
        if (shouldCalculate) {
          const sum = calculateSum(index, column.property)
          sums[index] = sum === '' ? '' : Number(sum.toFixed(2)).toLocaleString() // 格式化为两位小数并加上千分位
        } else {
          sums[index] = '--' // 不需要统计的列显示 '--'
        }
      })
      return sums
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 1 && index != 9 && index != 11 && index != 12 && index < 16) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.overviewYear = dictVal
          this.sectionInfo.responseYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      // 获取商务负责人
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.userList = res?.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
      // 获取商务名称列表
      this.$api.businessManage
        .getBusinessInfo()
        .then(res => {
          this.businessNameList = res?.data.map(v => ({
            id: v.id,
            dictName: v.businessName,
            dictVal: v.id,
          }))
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.business_individual {
  display: inline-block;
  text-align: center;
  width: 160px;
  font-size: 18px;
  .business_individual_title {
    font-size: 15px;
  }
  .business_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.statistics {
  font-size: 100%;
  font-weight: 900;
  color: #f53a3a;
  margin-left: 18px;
}
</style>
